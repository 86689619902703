/* eslint-disable @typescript-eslint/no-explicit-any */
import { HTMLAttributes, useEffect, useRef, useCallback } from 'react';
import { DyteParticipantTile } from '@dytesdk/react-ui-kit';
import { PRESET_TYPE } from 'configs/meeting';
import useMeeting from 'hooks/useMeeting';
import { useMeetingStore } from 'lib/meeting-store';
import { useStudentTalkTime } from 'hooks';
import { detectEmotionsFromStream } from 'utils';
import { NODE_ENV } from 'configs';

// Define participant state types
type ParticipantState = {
  hasActiveDetection: boolean;
  lastAttemptTime: number;
  cleanup: (() => void) | null;
  videoAvailable: boolean;
  lastInitializationId?: number; // Added this property
};

interface DyteVideoTrack extends MediaStreamTrack {
  enabled: boolean;
  readyState: MediaStreamTrackState;
}

export default function ActiveSpeaker(
  props: Omit<HTMLAttributes<HTMLDyteParticipantTileElement>, 'style'> & {
    isSmall?: true;
  },
) {
  const { isSmall } = props;
  const { meeting, classId, joinedParticipants, socketUser, tutorId } = useMeeting();
  const [size, states] = useMeetingStore((s) => [s.size, s.states]);

  // Track if component is mounted
  const isMountedRef = useRef(true);

  // Track instance for debugging
  const instanceIdRef = useRef(`instance-${Math.random().toString(36).substring(2, 10)}`);

  // Single state manager for participant tracking
  const participantStatesRef = useRef<Map<string, ParticipantState>>(new Map());

  // Constants for timing and retries
  const MAX_RETRY_INTERVAL = 5000; // 5 seconds between retries
  const VIDEO_INIT_DELAY = 3000; // Increased from 2s to 3s for better stability

  // Check if video track is valid and usable
  const isVideoTrackUsable = useCallback((videoTrack: DyteVideoTrack | undefined): boolean => {
    return Boolean(videoTrack && videoTrack.enabled && videoTrack.readyState === 'live');
  }, []);

  // Safely clean up detection for a participant
  const cleanupDetection = useCallback((participantId: string) => {
    const state = participantStatesRef.current.get(participantId);

    if (state) {
      // Call cleanup function if it exists
      if (state.cleanup && typeof state.cleanup === 'function') {
        try {
          state.cleanup();
        } catch (error) {
          console.error(`Error during cleanup for ${participantId}:`, error);
        }
      }

      // Clear any pending initialization timer
      const existingTimer = initTimersRef.current.get(participantId);
      if (existingTimer) {
        clearTimeout(existingTimer);
        initTimersRef.current.delete(participantId);
      }

      // Update state but keep in map for tracking
      participantStatesRef.current.set(participantId, {
        ...state,
        cleanup: null,
        hasActiveDetection: false,
        lastInitializationId: undefined, // Clear any initialization ID
      });
    }
  }, []);

  // Initialize emotion detection for a participant
  const initializeEmotionDetection = useCallback(
    async (participant: any, participantId: string): Promise<boolean> => {
      // Skip if component unmounted
      if (!isMountedRef.current) {
        return false;
      }

      // Get current state or create new entry
      const currentState = participantStatesRef.current.get(participantId) || {
        hasActiveDetection: false,
        lastAttemptTime: 0,
        cleanup: null,
        videoAvailable: false,
      };

      // If already has active detection, don't initialize again
      if (currentState.hasActiveDetection) {
        return true;
      }

      const videoTrack = participant?.videoTrack as DyteVideoTrack | undefined;
      const videoUsable = isVideoTrackUsable(videoTrack);

      // Update video availability state
      currentState.videoAvailable = videoUsable;

      // If video isn't usable, clean up any existing detection and exit
      if (!videoUsable) {
        if (currentState.hasActiveDetection) {
          cleanupDetection(participantId);
        }
        participantStatesRef.current.set(participantId, currentState);
        return false;
      }

      // Check if we need to rate limit attempts
      const now = Date.now();
      if (now - currentState.lastAttemptTime < MAX_RETRY_INTERVAL) {
        participantStatesRef.current.set(participantId, currentState);
        return currentState.hasActiveDetection;
      }

      // Update attempt time
      currentState.lastAttemptTime = now;
      participantStatesRef.current.set(participantId, currentState);

      const userType = participant.presetName === 'group_call_host' ? 'tutor' : 'student';
      const userId = participantId.split('-').pop();

      if (!userId) {
        return false;
      }

      // Track if we should continue with initialization
      let shouldContinue = true;

      try {
        // Clean up existing detection first
        if (currentState.cleanup) {
          currentState.cleanup();
          currentState.cleanup = null;
        }

        // Set a local variable to track if this initialization was canceled during async operation
        const initializationId = Date.now();
        currentState.lastInitializationId = initializationId;
        participantStatesRef.current.set(participantId, currentState);

        // Check if unmounted during async operation
        if (!isMountedRef.current) {
          return false;
        }

        // Double check the videoTrack is defined before passing it
        if (!videoTrack) {
          throw new Error('Video track is undefined');
        }

        const cleanup = await detectEmotionsFromStream(
          videoTrack,
          userType,
          userId,
          String(classId),
        );

        // Check if unmounted during async operation or if another initialization happened
        shouldContinue =
          isMountedRef.current &&
          participantStatesRef.current.has(participantId) &&
          participantStatesRef.current.get(participantId)?.lastInitializationId ===
            initializationId;

        if (!shouldContinue) {
          if (cleanup) cleanup();
          return false;
        }

        if (cleanup) {
          // Update state with new cleanup function
          participantStatesRef.current.set(participantId, {
            ...currentState,
            cleanup,
            hasActiveDetection: true,
          });
          return true;
        } else {
          participantStatesRef.current.set(participantId, {
            ...currentState,
            hasActiveDetection: false,
          });
          return false;
        }
      } catch (error) {
        // Only update state if we should continue
        if (shouldContinue) {
          participantStatesRef.current.set(participantId, {
            ...currentState,
            hasActiveDetection: false,
          });
        }
        console.error(`Error initializing emotion detection for ${participantId}:`, error);
        return false;
      }
    },
    [classId, cleanupDetection, isVideoTrackUsable],
  );

  // Handle video track state changes
  const initTimersRef = useRef<Map<string, number>>(new Map());

  // Update handleVideoUpdate function
  // Update handleVideoUpdate function to prevent multiple initialization attempts
  const handleVideoUpdate = useCallback(
    (participant: any) => {
      // Skip if component unmounted
      if (!isMountedRef.current) return;

      const participantId = participant?.customParticipantId;
      if (!participantId) return;

      const videoTrack = participant?.videoTrack as DyteVideoTrack | undefined;

      // Get current state
      const currentState = participantStatesRef.current.get(participantId) || {
        hasActiveDetection: false,
        lastAttemptTime: 0,
        cleanup: null,
        videoAvailable: false,
      };

      // Check if video is now available or was turned off
      const videoUsable = isVideoTrackUsable(videoTrack);
      const wasVideoAvailable = currentState.videoAvailable;

      // Only process if video state actually changed
      if (videoUsable !== wasVideoAvailable || !currentState.hasActiveDetection) {
        // Update video state
        currentState.videoAvailable = videoUsable;
        participantStatesRef.current.set(participantId, currentState);

        if (videoUsable) {
          // Clear any existing initialization timer
          const existingTimer = initTimersRef.current.get(participantId);
          if (existingTimer) {
            clearTimeout(existingTimer);
            initTimersRef.current.delete(participantId);
          }

          // Only schedule initialization if not already detected
          // AND if we don't have a pending timer already
          if (!currentState.hasActiveDetection && !initTimersRef.current.has(participantId)) {
            const timerId = window.setTimeout(() => {
              if (isMountedRef.current) {
                // Double check the participant still exists and still has usable video
                const updatedParticipant = joinedParticipants.find(
                  (p) => p.customParticipantId === participantId,
                );

                if (updatedParticipant) {
                  const currentVideoTrack = updatedParticipant?.videoTrack as
                    | DyteVideoTrack
                    | undefined;
                  const stillUsable = isVideoTrackUsable(currentVideoTrack);

                  if (stillUsable) {
                    initializeEmotionDetection(updatedParticipant, participantId);
                  }
                }
              }
              initTimersRef.current.delete(participantId); // Clean up timer reference
            }, VIDEO_INIT_DELAY);

            initTimersRef.current.set(participantId, timerId);
          }
        } else if (currentState.hasActiveDetection) {
          // If video was turned off and had detection, clean up
          cleanupDetection(participantId);
        }
      }
    },
    [cleanupDetection, initializeEmotionDetection, isVideoTrackUsable, joinedParticipants],
  );

  // Single combined useEffect for participant management
  useEffect(() => {
    // Set mounted flag
    isMountedRef.current = true;

    if (socketUser === PRESET_TYPE.VIEWER || NODE_ENV !== 'prod') return;

    if (!joinedParticipants?.length) {
      return;
    }

    // Get current participant IDs
    const currentParticipantIds = new Set(
      joinedParticipants.map((p) => p.customParticipantId).filter((id): id is string => !!id),
    );

    // Remove participants who left
    for (const participantId of participantStatesRef.current.keys()) {
      if (!currentParticipantIds.has(participantId)) {
        cleanupDetection(participantId);
        participantStatesRef.current.delete(participantId);
      }
    }

    // Set up event listeners and initialize for current participants
    joinedParticipants?.forEach((participant) => {
      const id = participant?.customParticipantId;
      if (!id) return;

      // Set up video update event listener if not already set
      participant.off('videoUpdate', handleVideoUpdate); // Remove any existing to prevent duplicates
      participant.on('videoUpdate', handleVideoUpdate);

      // Initial check of video state
      handleVideoUpdate(participant);

      // Try initial detection if needed
      const state = participantStatesRef.current.get(id);
      if (state?.videoAvailable && !state.hasActiveDetection) {
        initializeEmotionDetection(participant, id);
      }
    });

    // Cleanup all on unmount
    return () => {
      // Set unmounted flag first
      isMountedRef.current = false;

      // Clear all timers
      initTimersRef.current.forEach((timerId) => {
        clearTimeout(timerId);
      });
      initTimersRef.current.clear();

      // Clean up event listeners
      if (joinedParticipants) {
        joinedParticipants.forEach((participant) => {
          if (participant && typeof participant.off === 'function') {
            try {
              participant.off('videoUpdate', handleVideoUpdate);
            } catch (error) {
              console.error('Error removing event listener:', error);
            }
          }
        });
      }

      // Clean up all detections
      Array.from(participantStatesRef.current.keys()).forEach((participantId) => {
        try {
          cleanupDetection(participantId);
        } catch (error) {
          console.error(`Error cleaning up detection for ${participantId}:`, error);
        }
      });

      participantStatesRef.current.clear();
    };
  }, [
    joinedParticipants,
    socketUser,
    cleanupDetection,
    handleVideoUpdate,
    initializeEmotionDetection,
  ]);

  // Student talk time tracking
  if (socketUser !== PRESET_TYPE.VIEWER) {
    useStudentTalkTime();
  }

  // Message handler
  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      const { data } = event;
      if (data?.type === 'SEND_MESSAGE') {
        try {
          meeting.participants.broadcastMessage('tutorNudge', {
            data: event?.data?.payload?.data,
          });
        } catch (error) {
          console.error(`[${instanceIdRef.current}] Failed to send message:`, error);
        }
      }
    };
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [tutorId, meeting.participants]);

  return (
    <div className='w-full h-screen bg-gray-900'>
      <div className='w-full h-full p-2'>
        <div
          className={`grid w-full h-full gap-2 ${
            joinedParticipants?.length > 2 ? 'grid-cols-2' : 'grid-cols-1 place-items-center'
          }`}
        >
          {joinedParticipants?.map((participant) => (
            <div
              key={participant?.customParticipantId || participant?.id}
              className={`${joinedParticipants?.length === 1 ? 'w-full h-1/2' : 'w-full h-full'}`}
            >
              <DyteParticipantTile
                key={participant.id}
                participant={participant}
                meeting={meeting}
                size={isSmall ? 'lg' : size}
                states={states}
                {...props}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
                className='!w-full !h-full rounded-lg shadow-lg overflow-hidden'
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

import { datadogLogs } from '@datadog/browser-logs';
const API_ENDPOINT = import.meta.env.VITE_DATA_VORTEX_API_URL || '/api/emotions';
import { morphcastService, initMorphcastService } from './morphcastService';
import { MORPHCAST_API_KEY } from '../configs/config';

// Initialize MorphCast service early in your application
// You should call this once when your app starts
initMorphcastService(MORPHCAST_API_KEY);
// Initialize the worker outside your function
const imageWorker = new Worker(new URL('./imageWorker.ts', import.meta.url));
const pendingFrames = new Map();

// Set up the worker message handler
imageWorker.onmessage = function (e) {
  const { dataUrl, id } = e.data;

  // Get and resolve the promise for this frame
  const resolve = pendingFrames.get(id);
  if (resolve) {
    resolve(dataUrl);
    pendingFrames.delete(id);
  }
};

// Define emotion types
type EmotionType = {
  anger: number;
  contempt: number;
  disgust: number;
  fear: number;
  happy: number;
  neutral: number;
  sadness: number;
  surprise: number;
};

type DetectedEmotions = EmotionType & {
  confusion?: number;
  engagement?: number;
  valence?: number;
  arousal?: number;
  timestamp?: string;
  userId?: number;
  classId?: number;
  userType?: string;
  emotion?: string;
  frame?: string;
};

/**
 * Sends emotion data to the API endpoint
 * @param data The emotion data to send
 * @returns Promise that resolves when the data is sent
 */
function sendEmotionData(data: DetectedEmotions): void {
  // Fire the request without awaiting response
  fetch(API_ENDPOINT + "/v1/emotions", {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ data })
  }).catch(error => {
    // Only log errors from the fetch itself
    console.error('Failed to send emotion data:', error);
    datadogLogs.logger.error(`Failed to send emotion data: ${error}`);
  });

  // Optional: Log that we fired the request
  console.debug('Emotion data sent');
}

type EmissionRecord = {
  times: number[];
};

// Create a global map to track emissions
const userEmissionCounts = new Map<string, EmissionRecord>();

// Export utility functions for rate limiting
export function checkRateLimit(
  userId: string | undefined,
  classId: string | undefined,
  maxEmissionsPerMinute: number
): boolean {
  if (!userId || !classId) return true; // Rate limit if IDs are missing

  const userKey = `${userId}-${classId}`;
  const now = Date.now();

  // Get or initialize user's emission record
  if (!userEmissionCounts.has(userKey)) {
    userEmissionCounts.set(userKey, { times: [] });
  }

  const record = userEmissionCounts.get(userKey)!;

  // Remove old timestamps
  const oneMinuteAgo = now - 60000;
  while (record.times.length && record.times[0] < oneMinuteAgo) {
    record.times.shift();
  }

  // Check if rate limited
  if (record.times.length >= maxEmissionsPerMinute) {
    return true; // Rate limited
  }

  // Not rate limited
  return false;
}

export function recordEmission(
  userId: string | undefined,
  classId: string | undefined
): void {
  if (!userId || !classId) return;

  const userKey = `${userId}-${classId}`;
  const now = Date.now();

  // Get or initialize user's emission record
  if (!userEmissionCounts.has(userKey)) {
    userEmissionCounts.set(userKey, { times: [] });
  }

  const record = userEmissionCounts.get(userKey)!;
  record.times.push(now);
}

/**
 * Calculates emotion metrics from raw emotion data
 * @param emotions The raw emotion data
 * @returns The calculated metrics
 */
function calculateEmotionMetrics(emotions: EmotionType): {
  confusion: number;
  engagement: number;
  valence: number;
  arousal: number;
  attention?: number; // Add optional attention field
} {
  const confusion = emotions.anger || 0;

  const engagement =
    emotions.anger +
    emotions.contempt +
    emotions.disgust +
    emotions.fear +
    emotions.happy +
    emotions.neutral +
    emotions.sadness +
    emotions.surprise || 0;

  const valence = (
    confusion * -0.51 +
    emotions.disgust * -0.6 +
    emotions.fear * -0.64 +
    emotions.happy * 0.81 +
    emotions.sadness * -0.63 +
    emotions.surprise * 0.4 +
    emotions.neutral * 0.15 +
    emotions.contempt * -0.55
  ) / 4.29;

  const arousal = (
    confusion * 0.59 +
    emotions.disgust * 0.35 +
    emotions.fear * 0.61 +
    emotions.happy * 0.51 +
    emotions.sadness * 0.29 +
    emotions.surprise * 0.68 +
    emotions.neutral * 0.15 +
    emotions.contempt * 0.4
  ) / 3.58;

  return { confusion, engagement, valence, arousal };
  // attention will be added later from MorphCast results
}

/**
 * Gets the dominant emotion from emotion data
 * @param emotions The emotion data
 * @returns The dominant emotion or empty string if no emotions detected
 */
function getDominantEmotion(emotions: EmotionType): string {
  // Only consider standard emotions, not attention
  const standardEmotions = {
    anger: emotions.anger || 0,
    contempt: emotions.contempt || 0,
    disgust: emotions.disgust || 0,
    fear: emotions.fear || 0,
    happy: emotions.happy || 0,
    neutral: emotions.neutral || 0,
    sadness: emotions.sadness || 0,
    surprise: emotions.surprise || 0
  };

  const hasEmotions = Object.values(standardEmotions).some(value => value > 0);
  if (!hasEmotions) return '';

  return Object.keys(standardEmotions).reduce((a, b) =>
    standardEmotions[a as keyof typeof standardEmotions] >
      standardEmotions[b as keyof typeof standardEmotions] ? a : b
  );
}

/**
 * Validates that a MediaStreamTrack is available and usable for video processing
 * @param videoStream The MediaStreamTrack to validate
 * @returns Boolean indicating if the track is usable
 */
function validateVideoStream(videoStream: MediaStreamTrack): boolean {
  if (!videoStream) {
    console.warn('Missing video stream for emotion detection');
    return false;
  }

  if (!videoStream.enabled) {
    console.warn('Video stream is disabled (camera off)');
    return false;
  }

  if (videoStream.readyState !== 'live') {
    console.warn(`Video stream is not live: ${videoStream.readyState}`);
    return false;
  }

  return true;
}

/**
 * Detects emotions from a video stream
 * @param videoStream The MediaStreamTrack to analyze
 * @param userType The type of user (student/tutor)
 * @param userId The user ID
 * @param classId The class ID
 * @returns A cleanup function
 */
export async function detectEmotionsFromStream(
  videoStream: MediaStreamTrack | undefined,
  userType: string,
  userId?: string,
  classId?: string,
): Promise<(() => void) | null> {
  // Existing validation code
  if (!userId) {
    console.error('Missing userId for emotion detection');
    return null;
  }

  // Validate video stream is usable
  if (!videoStream || !validateVideoStream(videoStream)) {
    return null;
  }

  // Create video elements
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d', { willReadFrequently: true }); // Optimization for pixel reading
  const video = document.createElement('video');

  if (!context) {
    console.error('Failed to get canvas context');
    return null;
  }

  // Configure video
  video.autoplay = true;
  video.muted = true;
  video.srcObject = new MediaStream([videoStream]);

  // Create a unique ID for this detection instance
  const detectionId = `${userId}-${Date.now()}`;

  // Flag to track if this detection instance has been cleaned up
  let isCleanedUp = false;

  // Track active worker tasks for this instance
  const activeWorkerTasks = new Set<string>();

  return new Promise((resolve) => {
    // Handle errors during video loading
    const handleVideoError = () => {
      console.error(`Video element error for ${detectionId}`);
      cleanupResources();
      resolve(null);
    };

    video.addEventListener('error', handleVideoError);

    // Handle stream ended event
    const handleStreamEnded = () => {
      cleanupResources();
    };

    videoStream.addEventListener('ended', handleStreamEnded);

    // Clean up all resources created for this detection
    const cleanupResources = () => {
      if (isCleanedUp) return;

      isCleanedUp = true;

      clearInterval(detectionInterval);

      videoStream.removeEventListener('ended', handleStreamEnded);
      video.removeEventListener('error', handleVideoError);

      if (video.srcObject) {
        video.srcObject = null;
      }

      video.pause();
      video.remove();
      canvas.remove();

      // Clean up any pending frame requests for this instance
      for (const taskId of activeWorkerTasks) {
        pendingFrames.delete(taskId);
      }
      activeWorkerTasks.clear();
    };

    // Create detection interval reference (will be set after video loads)
    let detectionInterval: number | undefined;

    // Set up video loading handler
    video.onloadedmetadata = () => {
      // Double-check video is still valid
      if (isCleanedUp || !validateVideoStream(videoStream)) {
        cleanupResources();
        resolve(cleanupResources);
        return;
      }

      // Set canvas dimensions
      // Optimization: Use a smaller canvas size for processing
      const MAX_WIDTH = 640;
      const MAX_HEIGHT = 480;

      if (video.videoWidth > MAX_WIDTH || video.videoHeight > MAX_HEIGHT) {
        // Calculate aspect ratio
        const aspectRatio = video.videoWidth / video.videoHeight;

        if (video.videoWidth > video.videoHeight) {
          canvas.width = MAX_WIDTH;
          canvas.height = Math.floor(MAX_WIDTH / aspectRatio);
        } else {
          canvas.height = MAX_HEIGHT;
          canvas.width = Math.floor(MAX_HEIGHT * aspectRatio);
        }
      } else {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
      }

      // Start playing video
      video.play().catch((error: unknown) => {
        const errorMessage = error instanceof Error ? error.message : String(error);
        console.error(`Failed to play video: ${errorMessage}`);
        cleanupResources();
        resolve(cleanupResources);
      });

      // Start detection interval
      const MAX_EMISSIONS_PER_MINUTE = 60;

      // Then in the interval:
      detectionInterval = window.setInterval(async () => {
        // Skip if already cleaned up or video is not valid
        if (isCleanedUp || !validateVideoStream(videoStream)) {
          return;
        }

        // Check global rate limit
        if (checkRateLimit(userId, classId, MAX_EMISSIONS_PER_MINUTE)) {
          console.debug(`Rate limit reached for participant ${userId}`);
          return;
        }

        context.drawImage(video, 0, 0, canvas.width, canvas.height);

        // Get image data for emotion detection
        const imgData = context.getImageData(0, 0, canvas.width, canvas.height);
        const uint8ArrData = new Uint8Array(imgData.data);

        try {
          // Detect emotions with facialEye
          const detectedEmotions = await window.facialEye.test_emotion_image(
            uint8ArrData,
            canvas.width,
            canvas.height,
            true,
          ) as EmotionType;

          // Calculate metrics from facialEye results
          const metrics = calculateEmotionMetrics(detectedEmotions);
          const timestamp = new Date().toISOString();

          // Now analyze with MorphCast if available (non-blocking)
          morphcastService.analyzeFrame(imgData)
            .catch((error: unknown) => {
              const errorMessage = error instanceof Error ? error.message : String(error);
              console.debug(`MorphCast analysis error: ${errorMessage}`);
            });

          // Get MorphCast results to replace our attention data if available
          const morphcastResults = morphcastService.getLatestResults();
          if (morphcastResults.lastUpdated > 0 && Date.now() - morphcastResults.lastUpdated < 2000) {
            // Get attention value (0-1 range) from MorphCast instead of using facialEye's data
            const morphcastAttention = morphcastResults.attention ?? 0;

            // Log for debugging
            console.debug('MorphCast attention:', morphcastAttention);

            // Replace the attention value in the metrics with MorphCast's attention
            metrics.attention = morphcastAttention;

            // We're NOT recalculating emotions - just using the facialEye results for consistency
            // but replacing the attention value with MorphCast's
          }

          // Prepare base emotion data without frame
          const baseEmotionData: DetectedEmotions = {
            ...detectedEmotions,
            ...metrics,
            timestamp,
            userId: Number(userId),
            classId: Number(classId),
            userType,
            emotion: getDominantEmotion(detectedEmotions),
          };

          // Process frame in worker if needed
          const frameId = `${detectionId}-${Date.now()}`;
          activeWorkerTasks.add(frameId);

          // Create a promise that will be resolved when the worker sends back the dataURL
          const framePromise = new Promise<string>((resolve) => {
            pendingFrames.set(frameId, resolve);
          });

          // Clone the imgData to send to worker
          const clonedImgData = new Uint8Array(imgData.data);

          // Send the image data to the worker
          imageWorker.postMessage({
            imageData: clonedImgData.buffer,
            width: canvas.width,
            height: canvas.height,
            quality: 0.7,
            id: frameId
          }, [clonedImgData.buffer]);

          // Wait for the frame processing to complete
          framePromise.then((dataUrl) => {
            if (isCleanedUp) return;

            // Remove from active tasks
            activeWorkerTasks.delete(frameId);

            // Create the complete emotion data with frame
            const completeEmotionData = {
              ...baseEmotionData,
              frame: dataUrl
            };

            // Send complete data in a single API call
            sendEmotionData(completeEmotionData);
            recordEmission(userId, classId);
          }).catch((error: unknown) => {
            const errorMessage = error instanceof Error ? error.message : String(error);
            console.error(`Error processing frame in worker: ${errorMessage}`);
            activeWorkerTasks.delete(frameId);

            // Still send the emotion data even if frame processing failed
            sendEmotionData(baseEmotionData);
            recordEmission(userId, classId);
          });

        } catch (error: unknown) {
          if (error instanceof Error && error.message && error.stack && typeof error !== 'number') {
            datadogLogs.logger.info(
              `Error detecting emotions: ${error.message}, stack: ${error.stack}`,
            );
          }
        }
      }, 1000);

      // Return cleanup function
      resolve(cleanupResources);
    };

    // Handle timeout for video loading
    const loadTimeout = setTimeout(() => {
      console.warn(`Video load timeout for ${detectionId}`);
      cleanupResources();
      resolve(cleanupResources);
    }, 10000); // 10 second timeout for video to load

    // Clear timeout when video loads
    video.addEventListener('loadedmetadata', () => {
      clearTimeout(loadTimeout);
    });
  });
}

window.addEventListener('beforeunload', () => {
  imageWorker.terminate();
});
import { datadogLogs } from '@datadog/browser-logs';

// Define emotion types (matching your existing types)
export type EmotionType = {
    anger: number;
    contempt: number;
    disgust: number;
    fear: number;
    happy: number;
    neutral: number;
    sadness: number;
    surprise: number;
};

export type MorphcastEmotionResult = {
    // MorphCast uses slightly different names for emotions
    Angry: number;
    Disgust: number;
    Fear: number;
    Happy: number;
    Neutral: number;
    Sad: number;
    Surprise: number;
    dominantEmotion?: string;
};

export type MorphcastArousalValenceResult = {
    arousal: number;
    valence: number;
    quadrant: string;
    affects38: Record<string, number>;
    affects98: Record<string, number>;
};

export type MorphcastResults = {
    emotions?: MorphcastEmotionResult;
    arousalValence?: MorphcastArousalValenceResult;
    attention?: number;
    lastUpdated: number;
};

// Type definition for the custom source
interface CustomSource {
    analyzeFrame: (imageData: ImageData) => void;
    getFrame: () => Promise<ImageData>;
    start: () => void;
    stop: () => void;
    readonly stopped: boolean;
    crtImgData: ImageData | null;
    resolver: ((imageData: ImageData) => void) | null;
}

// Define types for the MorphCast SDK
type MorphcastModuleResult = {
    setSmoothness: (value: number) => void;
    setThreshold?: (value: number) => void;
};

type MorphcastLoadResult = {
    start: () => void;
    stop: () => void;
    getModule: (name: string) => MorphcastModuleResult;
};

// Define CYLoader interface for method chaining
interface CYLoader {
    licenseKey: (key: string) => CYLoader;
    source: (source: CustomSource) => CYLoader;
    maxInputFrameSize: (size: number) => CYLoader;
    powerSave: (value: number) => CYLoader;
    addModule: (name: string, config?: Record<string, unknown>) => CYLoader;
    load: () => Promise<MorphcastLoadResult>;
}

// Singleton class to manage MorphCast SDK
class MorphcastService {
    private static instance: MorphcastService;
    private initialized = false;
    private initializing = false;
    private customSource: CustomSource | null = null;
    private latestResults: MorphcastResults = { lastUpdated: 0 };
    private licenseKey: string = '';

    private constructor() {
        // Private constructor for singleton pattern
    }

    public static getInstance(): MorphcastService {
        if (!MorphcastService.instance) {
            MorphcastService.instance = new MorphcastService();
        }
        return MorphcastService.instance;
    }

    /**
     * Set the MorphCast license key
     */
    public setLicenseKey(key: string): MorphcastService {
        this.licenseKey = key;
        return this;
    }

    /**
     * Initialize the MorphCast SDK
     */
    public async initialize(): Promise<boolean> {
        if (this.initialized) {
            return true;
        }

        if (this.initializing) {
            // Wait for current initialization to complete
            return new Promise<boolean>((resolve) => {
                const checkInterval = setInterval(() => {
                    if (this.initialized) {
                        clearInterval(checkInterval);
                        resolve(true);
                    }
                }, 100);
            });
        }

        this.initializing = true;

        if (!window.CY) {
            console.error('MorphCast SDK not loaded. Make sure to include the SDK script.');
            this.initializing = false;
            return false;
        }

        if (!this.licenseKey) {
            console.error('MorphCast license key not provided.');
            this.initializing = false;
            return false;
        }

        try {
            // Create a custom source for one-shot analysis
            this.customSource = {
                analyzeFrame(imageData: ImageData) {
                    if (this.resolver) {
                        this.resolver(imageData);
                        this.resolver = null;
                    } else {
                        this.crtImgData = imageData;
                    }
                },
                getFrame() {
                    if (this.crtImgData) {
                        const p = Promise.resolve(this.crtImgData);
                        this.crtImgData = null;
                        return p;
                    } else {
                        return new Promise<ImageData>(res => this.resolver = res);
                    }
                },
                start() { },
                stop() { },
                get stopped() { return false; },
                crtImgData: null as ImageData | null,
                resolver: null as ((imageData: ImageData) => void) | null
            } as CustomSource;

            // Initialize the MorphCast SDK
            return await window.CY.loader()
                .licenseKey(this.licenseKey)
                .source(this.customSource)
                .maxInputFrameSize(640)
                .powerSave(0) // Disable dynamic adjustment for analysis
                .addModule(window.CY.modules().FACE_DETECTOR.name, { maxInputFrameSize: 640, smoothness: 0 })
                .addModule(window.CY.modules().FACE_EMOTION.name, { smoothness: 0 })
                .addModule(window.CY.modules().FACE_AROUSAL_VALENCE.name, { smoothness: 0 })
                .addModule(window.CY.modules().FACE_ATTENTION.name, { smoothness: 0 }) // Add attention module
                .load()
                .then((result: MorphcastLoadResult) => {
                    result.start();
                    this.setupEventListeners();
                    this.initialized = true;
                    this.initializing = false;
                    console.log('MorphCast SDK initialized successfully');
                    return true;
                })
                .catch((error: unknown) => {
                    const errorMessage = error instanceof Error ? error.message : String(error);
                    console.error('Failed to initialize MorphCast SDK:', errorMessage);
                    datadogLogs.logger.error(`Failed to initialize MorphCast SDK: ${errorMessage}`);
                    this.initializing = false;
                    return false;
                });
        } catch (error: unknown) {
            const errorMessage = error instanceof Error ? error.message : String(error);
            console.error('Failed to initialize MorphCast SDK:', errorMessage);
            datadogLogs.logger.error(`Failed to initialize MorphCast SDK: ${errorMessage}`);
            this.initializing = false;
            return false;
        }
    }

    /**
     * Set up event listeners for MorphCast events
     */
    private setupEventListeners(): void {
        // Define types for MorphCast events
        interface MorphcastEmotionEvent extends Event {
            detail: {
                output: {
                    emotion: MorphcastEmotionResult;
                    dominantEmotion: string;
                }
            }
        }

        interface MorphcastArousalValenceEvent extends Event {
            detail: {
                output: {
                    arousal: number;
                    valence: number;
                    quadrant: string;
                    affects38: Record<string, number>;
                    affects98: Record<string, number>;
                }
            }
        }

        interface MorphcastAttentionEvent extends Event {
            detail: {
                output: {
                    attention: number;
                }
            }
        }

        // Listen for emotion events
        window.addEventListener(window.CY.modules().FACE_EMOTION.eventName, ((evt: MorphcastEmotionEvent) => {
            if (evt.detail?.output) {
                this.latestResults.emotions = evt.detail.output.emotion;
                if (this.latestResults.emotions) {
                    this.latestResults.emotions.dominantEmotion = evt.detail.output.dominantEmotion;
                }
                this.latestResults.lastUpdated = Date.now();
            }
        }) as EventListener);

        // Listen for arousal/valence events
        window.addEventListener(window.CY.modules().FACE_AROUSAL_VALENCE.eventName, ((evt: MorphcastArousalValenceEvent) => {
            if (evt.detail?.output) {
                this.latestResults.arousalValence = {
                    arousal: evt.detail.output.arousal,
                    valence: evt.detail.output.valence,
                    quadrant: evt.detail.output.quadrant,
                    affects38: evt.detail.output.affects38,
                    affects98: evt.detail.output.affects98
                };
                this.latestResults.lastUpdated = Date.now();
            }
        }) as EventListener);

        // Listen for attention events
        window.addEventListener(window.CY.modules().FACE_ATTENTION.eventName, ((evt: MorphcastAttentionEvent) => {
            if (evt.detail?.output) {
                this.latestResults.attention = evt.detail.output.attention;
                this.latestResults.lastUpdated = Date.now();
            }
        }) as EventListener);
    }

    /**
     * Analyze a frame with MorphCast
     * @param frame ImageData to analyze
     */
    public async analyzeFrame(frame: ImageData): Promise<void> {
        if (!this.initialized) {
            const success = await this.initialize();
            if (!success) {
                return Promise.reject(new Error('Failed to initialize MorphCast SDK'));
            }
        }

        if (!this.customSource) {
            return Promise.reject(new Error('MorphCast custom source not initialized'));
        }

        try {
            this.customSource.analyzeFrame(frame);
            // Results will be handled by event listeners
            return Promise.resolve();
        } catch (error: unknown) {
            const errorMessage = error instanceof Error ? error.message : String(error);
            console.error('Error analyzing frame with MorphCast:', errorMessage);
            return Promise.reject(new Error(errorMessage));
        }
    }

    /**
     * Get the latest results from MorphCast
     */
    public getLatestResults(): MorphcastResults {
        return { ...this.latestResults };
    }

    /**
     * Convert MorphCast emotion result to standard EmotionType format
     */
    public convertToStandardEmotions(morphcastEmotion?: MorphcastEmotionResult): EmotionType | null {
        if (!morphcastEmotion) return null;

        return {
            anger: morphcastEmotion.Angry || 0,
            contempt: 0, // MorphCast doesn't have contempt, default to 0
            disgust: morphcastEmotion.Disgust || 0,
            fear: morphcastEmotion.Fear || 0,
            happy: morphcastEmotion.Happy || 0,
            neutral: morphcastEmotion.Neutral || 0,
            sadness: morphcastEmotion.Sad || 0,
            surprise: morphcastEmotion.Surprise || 0
        };
    }
}

// Add type definitions for the MorphCast SDK
declare global {
    interface Window {
        CY: {
            loader: () => {
                licenseKey: (key: string) => CYLoader;
                source: (source: CustomSource) => CYLoader;
                maxInputFrameSize: (size: number) => CYLoader;
                powerSave: (value: number) => CYLoader;
                addModule: (name: string, config?: Record<string, unknown>) => CYLoader;
                load: () => Promise<MorphcastLoadResult>;
            };
            modules: () => {
                FACE_DETECTOR: { name: string; eventName: string };
                FACE_EMOTION: { name: string; eventName: string };
                FACE_AROUSAL_VALENCE: { name: string; eventName: string };
                FACE_ATTENTION: { name: string; eventName: string };
                CAMERA: { name: string; eventName: string };
                EVENT_BARRIER: { name: string; eventName: string };
                [key: string]: { name: string; eventName: string };
            };
            createSource: {
                fromCamera: (config?: { constraints?: MediaStreamConstraints; video?: HTMLVideoElement }) => CustomSource;
                fromVideoElement: (element: HTMLVideoElement) => CustomSource;
                fromVideoUrl: (url: string) => CustomSource;
            };
        };
        facialEye: {
            init_fcet(EMOTION_USERNAME: string, EMOTION_API_KEY: string): unknown;
            test_emotion_image: (
                data: Uint8Array,
                width: number,
                height: number,
                highAccuracy: boolean
            ) => Promise<EmotionType>;
        };
    }
}

// Export singleton instance
export const morphcastService = MorphcastService.getInstance();

// Export a function for easy import and initialization
export function initMorphcastService(licenseKey: string): Promise<boolean> {
    return morphcastService.setLicenseKey(licenseKey).initialize();
}
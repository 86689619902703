import { DyteProvider, useDyteClient } from '@dytesdk/react-web-core';
import { DyteRecording } from '@dytesdk/recording-sdk';
import { useEffect, useRef } from 'react';

import { DyteSpinner } from '@dytesdk/react-ui-kit';
import useSocketConnection from 'hooks/useSocketConnection';
import { AllContent, Participants, TimeComponent } from 'components';
import { IRecordingView } from 'types';
import { useAppDispatch } from 'hooks';
import { resetClassNotesState } from 'store/slice/notesHtmlData.slice';
import MeetingAudio from 'components/MeetingAudio';
import { datadogLogs } from '@datadog/browser-logs';

export default function UIKitMeeting({ authToken, classId }: IRecordingView) {
  // Refs
  const elementRef = useRef(null);

  // Dyte
  const [client, initClient] = useDyteClient();

  // Redux
  const dispatch = useAppDispatch();

  // Socket
  const socket = useSocketConnection({ classId: classId });

  // States
  // const [detectedEmotionsMap, setDetectedEmotionsMap] = useState<Record<string, any>>({});

  // Initialize DyteMeeting
  useEffect(() => {
    if (!authToken) {
      return;
    }
    async function setupDyteMeeting() {
      try {
        const recordingSDK = new DyteRecording({});
        const meetingObj = await initClient({
          authToken,
          defaults: {
            audio: false,
            video: false,
          },
        });

        if (meetingObj) {
          await recordingSDK.init(meetingObj);
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        datadogLogs.logger.error('Error initializing Dyte meeting', {
          label: 'dyte-error',
          source: 'recording-app',
          error: error?.message,
          code: error?.code,
        });
      }
    }
    setupDyteMeeting();
  }, [authToken]);

  useEffect(() => {
    if (classId) {
      dispatch(resetClassNotesState(classId));
    }
  }, [classId, dispatch]);

  if (!client) {
    return (
      <div className='flex items-center justify-center flex-1 h-full'>
        <DyteSpinner />
      </div>
    );
  }

  return (
    <DyteProvider value={client}>
      <div
        style={{
          backgroundColor: 'rgba(var(--dyte-colors-background-1000, 8 8 8))',
        }}
        ref={elementRef}
        className='grid h-screen grid-cols-12 gap-4 overflow-hidden'
      >
        <TimeComponent />
        {/* {MORPHCAST_ACTIVE === "true" ? (
        <Attention socket={socket} meeting={client} />
        ) : (
        <Emotion detectedEmotionsMap={detectedEmotionsMap} />
          )} */}
        <div className='flex-1 h-full col-span-8'>
          <AllContent socket={socket || undefined} />
        </div>
        <div className='h-full col-span-4'>
          {/* setDetectedEmotionsMap={setDetectedEmotionsMap} pass this to participant when we want to show the emotion data in recording */}
          <Participants />
        </div>
      </div>
      <MeetingAudio meeting={client} />
    </DyteProvider>
  );
}

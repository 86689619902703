import { useState, useEffect, useRef } from 'react';
import { Socket, io } from 'socket.io-client';
import { useSearchParams } from 'react-router-dom';
import { datadogLogs } from '@datadog/browser-logs';

const useSocketConnection = (props: { classId: string | null }) => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const [searchParams] = useSearchParams();
  const socketRef = useRef<Socket | null>(null);

  useEffect(() => {
    const id = '';
    const classId = props.classId || searchParams.get('classId');
    const userType = 'recorder';
    const socketServerUrl = import.meta.env.VITE_SOCKET_SERVER_URL;

    // Only establish connection if we have required parameters
    if (userType && classId && socketServerUrl) {
      // Log connection attempt
      datadogLogs.logger.info(
        `classId: ${classId}, userType: ${userType} trying to connect socket ${socketServerUrl}`,
        { userType, classId },
      );

      // Clean up any existing connection before creating a new one
      if (socketRef.current) {
        socketRef.current.disconnect();
      }

      // Create new socket connection
      const socketFinal = io(socketServerUrl, {
        query: {
          id,
          userType,
          classId,
        },
        transports: ['websocket'],
        reconnectionAttempts: 5,
        reconnectionDelay: 5000,
      });

      // Store in ref for cleanup
      socketRef.current = socketFinal;

      // Update state
      setSocket(socketFinal);

      // Set up connect event handler
      socketFinal.on('connect', () => {
        datadogLogs.logger.info(`classId: ${classId}, userType: ${userType} Socket Connected`, {
          userType,
          classId,
        });
      });

      // Set up disconnect event handler for logging
      socketFinal.on('disconnect', (reason) => {
        datadogLogs.logger.info(
          `classId: ${classId}, userType: ${userType} Socket Disconnected: ${reason}`,
          {
            userType,
            classId,
            reason,
          },
        );
      });

      // Proper cleanup function
      return () => {
        if (socketRef.current) {
          socketRef.current.disconnect();
          socketRef.current = null;
        }
      };
    }

    return undefined;
  }, [props.classId, searchParams]); // Include dependencies explicitly

  return socket;
};

export default useSocketConnection;
